import { memo } from 'react'

import { Badge, Box, Group, Image, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import type ShowtimeContext from 'types/ShowtimeContext'
import { formatShowtimeTime } from 'utils/showtimes'

function ShowtimeCard({
  showtimeContext,
}: {
  showtimeContext: ShowtimeContext
}) {
  const { showtime, movie, theater, price } = showtimeContext

  return (
    <Group className="text-center w-full mx-auto pr-2 bg-white rounded-lg shadow-sm overflow-hidden ">
      <Image
        src={movie.posterPath}
        alt={`Affiche du film ${movie.titleVf}`}
        className="mx-auto ml-0 mr-auto w-[45%] min-w-[45%]"
      />
      <Stack className="text-center w-[45%] mx-auto" gap={4}>
        <Stack gap={2}>
          <p className="text-lg capitalize">
            {showtimeContext.showtimeDateStr}
          </p>
          <h2 className="text-2xl font-semibold">
            {formatShowtimeTime(showtime.showtime)}
          </h2>
          {showtimeContext.endTime > dayjs() && (
            <p className="italic text-zinc-500 mb-2">
              fin à {formatShowtimeTime(showtimeContext.endTime)}
            </p>
          )}
          <p className="inline-block text-lg text-zinc-900">{theater.name}</p>
        </Stack>
        {price !== '-' ? (
          <Box className="bg-">
            <p className="text-md italic">Plein tarif {price}</p>
          </Box>
        ) : null}
        <Group justify="center" className="mt-1">
          {showtime.extraInfo ? (
            <Badge className="bg-[#2D75F6] text-white">
              {showtime.extraInfo}
            </Badge>
          ) : null}
          {showtime.is3d ? (
            <Badge className="bg-[#2D75F6] text-white">3D</Badge>
          ) : null}
        </Group>
      </Stack>
    </Group>
  )
}

export default memo(ShowtimeCard)
